import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useAlert } from '@pangaea/shared/src/tools/alert';
import { Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import UpdatePasswordForm, {
    UpdatePasswordEntity,
} from '../../features/auth/UpdatePasswordForm';

const CreatePassword = () => {
    const navigate = useNavigate();
    const setAlert = useAlert();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const token = searchParams.get('t');
        const code = searchParams.get('c');
        if (token == null || code == null) {
            navigate('/auth/connexion');
        }
    }, [searchParams, navigate]);

    const value: Partial<UpdatePasswordEntity> = useMemo(
        () => ({
            token: searchParams.get('t') || undefined,
            code: searchParams.get('c') || undefined,
        }),
        [searchParams]
    );

    const handleSuccess = useCallback(() => {
        setAlert('Mot de passe créé avec succès.', 'success');
        navigate('/auth/connexion');
    }, [navigate, setAlert]);

    return (
        <>
            <Typography variant="h2" component="h1" mt={4} mb={3}>
                Création mot de passe
            </Typography>

            <UpdatePasswordForm value={value} onSuccess={handleSuccess} />
        </>
    );
};

export default CreatePassword;
