import {
    IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
    useTheme,
} from '@mui/material';
import {
    fieldToTextField,
    TextFieldProps as FormikTextFieldProps,
} from 'formik-mui';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PasswordField = (textFieldProps: TextFieldProps) => {
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);

    const toggleVisibility = () => {
        setShowPassword(showPassword === false);
    };

    return (
        <TextField
            {...textFieldProps}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                ...theme.components?.MuiTextField?.defaultProps?.InputProps,
                endAdornment: (
                    <InputAdornment position="end" style={{ outline: 'none' }}>
                        <IconButton
                            aria-label="changer la visibilité"
                            role="button"
                            onClick={() => toggleVisibility()}
                        >
                            {!showPassword && <VisibilityIcon />}
                            {showPassword && <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export const FormikPasswordField = (props: FormikTextFieldProps) => {
    return <PasswordField {...fieldToTextField(props)} />;
};

export default PasswordField;
