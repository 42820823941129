import React, { useEffect, useMemo } from 'react';
import { ApolloError, gql, useQuery } from '@apollo/client';
import { getCurrentUser } from '@pangaea/shared/src/security/currentUser';
import { entityToId } from '@pangaea/shared/src/utils/form-helpers';
import { useAlert } from '@pangaea/shared/src/tools/alert';
import { getIdFromIri } from '@pangaea/shared/src/utils/iri-helpers';
import getCurrentDate from '@pangaea/shared/src/utils/getCurrentDate';
import ListContractsResponse from '@pangaea/shared/src/features/contract/ListContractsResponse';
import DestinationTypeCodes from '@pangaea/shared/src/features/destinationType/DestinationTypeCodes';
import CONFIGS from '../../configs';
import SideBarListItemLink from './SideBarListItemLink';

export const GET_CONTRACTS = gql`
    query GetContracts(
        $entity: String
        $order: [ContractFilter_order]
        $page: Int
        $itemsPerPage: Int
        $destination_returnDate: [ContractFilter_destination_returnDate]
        $opened: Boolean
    ) {
        contracts(
            order: $order
            page: $page
            itemsPerPage: $itemsPerPage
            entity: $entity
            destination_returnDate: $destination_returnDate
            opened: $opened
        ) {
            collection {
                id
                destination {
                    id
                    destination
                    label
                    type {
                        code
                    }
                }
            }
        }
    }
`;

interface ContractsMenuProps {
    onError?: (error: ApolloError) => void;
}

const ContractsMenu = ({ onError }: ContractsMenuProps) => {
    const entity = useMemo(() => getCurrentUser(CONFIGS.localStorageKeys), []);
    const setAlert = useAlert();
    const now = useMemo(() => getCurrentDate(), []);
    const { loading, data, error } = useQuery<ListContractsResponse>(
        GET_CONTRACTS,
        {
            fetchPolicy: 'network-only',
            variables: {
                page: 1,
                itemsPerPage: 10000,
                order: [{ destination_destination: 'ASC' }],
                entity: entityToId(entity),
                destination_returnDate: [{ after: now }],
                opened: true,
            },
        }
    );

    useEffect(() => {
        if (error) {
            setAlert('Une erreur est survenue.', 'error');
            onError && onError(error);
        }
    }, [error, setAlert, onError]);

    if (loading) {
        return null;
    }

    return (
        <>
            {data?.contracts?.collection?.map((contract) => {
                const code = contract.destination?.type?.code;
                return (
                    <SideBarListItemLink
                        key={contract.id}
                        to={`/contrats/${
                            code === DestinationTypeCodes.PSV
                                ? 'sports'
                                : 'voyages'
                        }/${getIdFromIri(contract.id)}`}
                    >
                        {contract?.destination?.destination}
                        {` - ${contract?.destination?.label}`}
                    </SideBarListItemLink>
                );
            })}
        </>
    );
};

export default ContractsMenu;
