import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Paper } from '@mui/material';
import PictureAutoAjusted from '@pangaea/shared/src/ui/PictureAutoAjusted';

const LayoutConnection = () => {
    return (
        <Paper
            data-testid="LayoutConnexion"
            sx={{
                width: { sm: '90%', md: '75%', lg: '65%', xl: '50%' },
                minHeight: '60%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                borderRadius: 4,
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    width: '50%',
                    display: { xs: 'none', sm: 'none', md: 'block' },
                }}
            >
                <PictureAutoAjusted
                    src="/images/image_connexion_front.jpg"
                    alt="Destination de voyage organisé par Pangaea"
                />
            </Box>
            <Box width="50%" p={5}>
                <Box
                    component="img"
                    sx={{ width: '40%' }}
                    src="/images/logo-pangaea-voyage.png"
                    alt="logo Pangaea voyage"
                />
                <Outlet />
            </Box>
        </Paper>
    );
};

export default LayoutConnection;
