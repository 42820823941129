import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from '../layout/Header';
import SideBar from '../layout/SideBar/SideBar';

const Layout = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar />
            <Container sx={{ pb: 4 }}>
                <Header />
                <Outlet />
            </Container>
        </Box>
    );
};

export default Layout;
