import sharedInitApollo from '@pangaea/shared/src/apollo/initApollo';
import { history } from '@pangaea/shared/src/router';
import EntityResponse from '@pangaea/shared/src/features/entity/EntityResponse.model';
import CONFIGS from '../configs';

interface RefreshTokenEntityResponse {
    entity: EntityResponse;
}

const initApollo = () => {
    return sharedInitApollo<RefreshTokenEntityResponse>(
        CONFIGS.apiEntrypoint,
        CONFIGS.localStorageKeys,
        {
            refreshTokenGraphqlQuery: `
                mutation RefreshToken($refreshToken: String!) {
                    refreshTokenEntity(input: {refreshToken: $refreshToken}) {
                        entity {
                            token
                            roles
                        }
                    }
                }
            `,
            jwtFieldInResponse: 'refreshTokenEntity',
            handleRefreshTokenError: () => history.push('/deconnexion'),
            extractToken: (payload) => payload.entity.token!,
            extractRoles: (payload) => payload.entity.roles!,
        }
    );
};

export default initApollo;
