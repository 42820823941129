import {
    Navigate,
    NavigateProps,
    Params,
    To,
    useParams,
} from 'react-router-dom';
import React from 'react';

interface NavigateWithParamsProps extends Omit<NavigateProps, 'to'> {
    to: NavigateProps['to'] | ((params: Readonly<Params<string>>) => To);
}

const NavigateWithParams = ({ to, ...rest }: NavigateWithParamsProps) => {
    const params = useParams();
    let toValue;
    if (typeof to === 'function') {
        toValue = to(params);
    } else {
        toValue = to;
    }
    return <Navigate to={toValue} {...rest} />;
};

export default NavigateWithParams;
