import React, { useMemo } from 'react';
import { Box, Alert } from '@mui/material';
import SubmitButton from '@pangaea/shared/src/formik/SubmitButton';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import { useMutation, gql, FetchResult, ApolloError } from '@apollo/client';
import Yup from '@pangaea/shared/src/yup/yupFr';
import { useAlert } from '@pangaea/shared/src/tools/alert';
import { handleApolloError } from '@pangaea/shared/src/utils/error-helpers';
import { initString } from '@pangaea/shared/src/utils/form-helpers';
import { FormikPasswordField } from '@pangaea/shared/src/formik/PasswordField';

export const UPDATEPASSWORD_ENTITY = gql`
    mutation updatePasswordEntity(
        $token: String!
        $code: String!
        $plainPassword: String!
    ) {
        updatePasswordEntity(
            input: { token: $token, code: $code, plainPassword: $plainPassword }
        ) {
            clientMutationId
        }
    }
`;

const updatePasswordSchema = Yup.object().shape({
    token: Yup.string().label('La clef').required(),
    code: Yup.string().label('Le code').required(),
    plainPassword: Yup.string().label('Le mot de passe').required(),
    plainPasswordConfirmation: Yup.string()
        .test(
            'match',
            'Les mots de passe ne correspondent pas.',
            function isSamePassword(this: any) {
                return (
                    this.parent.plainPassword ===
                    this.parent.plainPasswordConfirmation
                );
            }
        )
        .label('La confirmation du mot de passe')
        .required(),
});

export interface UpdatePasswordEntity {
    token: string;
    code: string;
    plainPassword: string;
    plainPasswordConfirmation: string;
}
export type UpdatePasswordResult = {
    updatePasswordEntity?: { clientMutationId?: number };
};

const initValue = (
    value: Partial<UpdatePasswordEntity>
): UpdatePasswordEntity => ({
    token: initString(value?.token),
    code: initString(value?.code),
    plainPassword: '',
    plainPasswordConfirmation: '',
});

interface ForgetPasswordFormProps {
    value?: Partial<UpdatePasswordEntity>;
    onSuccess?: () => void;
    onError?: (err: ApolloError) => void;
}

const UpdatePasswordForm = ({
    value,
    onSuccess,
    onError,
}: ForgetPasswordFormProps) => {
    const setAlert = useAlert();
    const [mutate] = useMutation(UPDATEPASSWORD_ENTITY);

    const initialValues: UpdatePasswordEntity = useMemo(
        () => initValue(value || {}),
        [value]
    );
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={updatePasswordSchema}
            onSubmit={(
                values: UpdatePasswordEntity,
                {
                    setSubmitting,
                    setErrors,
                }: FormikHelpers<UpdatePasswordEntity>
            ) => {
                mutate({ variables: values })
                    .finally(() => {
                        setSubmitting(false);
                    })
                    .then((res: FetchResult<UpdatePasswordResult>) => {
                        if (!res.data?.updatePasswordEntity) {
                            throw Error('Missing data result');
                        }
                        onSuccess && onSuccess();
                    })
                    .catch(
                        handleApolloError(
                            setErrors,
                            setAlert,
                            updatePasswordSchema,
                            onError
                        )
                    );
            }}
        >
            <Form>
                {!initialValues.code && (
                    <Alert variant="filled" severity="info">
                        Si un compte existe avec cette adresse, un email
                        contenant un code à saisir ci-dessous vous a été envoyé.
                    </Alert>
                )}
                <Box mb={3}>
                    {!initialValues.code && (
                        <Field
                            component={TextField}
                            inputProps={{ 'data-testid': 'code' }}
                            id="code"
                            label="Code"
                            name="code"
                        />
                    )}
                    <Field
                        component={FormikPasswordField}
                        inputProps={{ 'data-testid': 'plainPassword' }}
                        id="plainPassword"
                        label="Nouveau mot de passe"
                        name="plainPassword"
                        helperText="Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre, un caractère spécial et faire 8 caractères minimum."
                    />
                    <Field
                        component={FormikPasswordField}
                        inputProps={{
                            'data-testid': 'plainPasswordConfirmation',
                        }}
                        id="plainPasswordConfirmation"
                        label="Confirmer votre mot de passe"
                        name="plainPasswordConfirmation"
                    />
                </Box>
                <SubmitButton
                    buttonProps={{ 'data-testid': 'updatePasswordFormSubmit' }}
                />
            </Form>
        </Formik>
    );
};

export default UpdatePasswordForm;
