import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useAlert } from '@pangaea/shared/src/tools/alert';
import { Typography } from '@mui/material';
import UpdatePasswordForm, {
    UpdatePasswordEntity,
} from '../../features/auth/UpdatePasswordForm';

interface UpdatePasswordLocationState {
    token?: string;
}

const UpdatePassword = () => {
    const navigate = useNavigate();
    const setAlert = useAlert();
    const location = useLocation();
    const state = location.state as UpdatePasswordLocationState;

    useEffect(() => {
        if (state == null || state.token == null) {
            navigate('/auth/mot-de-passe-oublie');
        }
    }, [state, navigate, setAlert]);

    const value: Partial<UpdatePasswordEntity> = useMemo(
        () => ({
            token: state?.token,
        }),
        [state]
    );

    const handleSuccess = useCallback(() => {
        setAlert('Mot de passe changé avec succès.', 'success');
        navigate('/auth/connexion');
    }, [navigate, setAlert]);

    return (
        <>
            <Typography variant="h2" component="h1" mt={4} mb={3}>
                Nouveau mot de passe
            </Typography>

            <UpdatePasswordForm value={value} onSuccess={handleSuccess} />
        </>
    );
};

export default UpdatePassword;
