import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router';
import RequireAnonymous from '@pangaea/shared/src/security/RequireAnonymous';
import RequireAuth from '@pangaea/shared/src/security/RequireAuth';
import RequireRole from '@pangaea/shared/src/security/RequireRole';
import NavigateWithParams from '@pangaea/shared/src/router/NavigateWithParams';
import Home from './Home';
import Layout from './Layout';
import AuthPages from './auth/AuthPages';
import Logout from './Logout';

const ContractsPages = lazy(() => import('./contracts/ContractsPages'));
const ProfilePage = lazy(() => import('./Profile'));

const PageLoader = () => <></>;

/**
 * Point d'entrée pour déclarer les pages de l'application.
 */
const Pages = () => {
    return (
        <Suspense fallback={<PageLoader />}>
            <Routes>
                <Route
                    path="/auth/*"
                    element={
                        <RequireAnonymous>
                            <AuthPages />
                        </RequireAnonymous>
                    }
                />
                <Route
                    path="/deconnexion"
                    element={
                        <RequireAuth>
                            <Logout />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <Layout />
                        </RequireAuth>
                    }
                >
                    <Route
                        index
                        element={
                            <RequireRole
                                role="ROLE_COMPLETED"
                                redirectTo="/mon-profil"
                            >
                                <Home />
                            </RequireRole>
                        }
                    />
                    <Route
                        path="/contrats/*"
                        element={
                            <RequireRole
                                role="ROLE_COMPLETED"
                                redirectTo="/mon-profil"
                            >
                                <ContractsPages />
                            </RequireRole>
                        }
                    />
                    {/* Route utilisé uniquement pour la rétrocompatibilité */}
                    <Route
                        path="/voyages/:contractId"
                        element={
                            <NavigateWithParams
                                to={(params) =>
                                    `/contrats/${params.contractId}`
                                }
                                replace
                            />
                        }
                    />
                    <Route path="/mon-profil" element={<ProfilePage />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default Pages;
