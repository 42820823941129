import React from 'react';
import { Drawer, List } from '@mui/material';
import SideBarHeader from './SideBarHeader';
import SideBarCollapseMenu from './SideBarCollapseMenu';
import SideBarListItemLink from './SideBarListItemLink';
import ContractsMenu from './ContractsMenu';

const SideBar = () => {
    return (
        <Drawer variant="permanent" anchor="left">
            <SideBarHeader />
            <List component="nav" sx={{ backgroundColor: 'secondary.light' }}>
                <SideBarCollapseMenu title="Mes voyages" path="/voyages">
                    <ContractsMenu />
                </SideBarCollapseMenu>

                <SideBarListItemLink to="/mon-profil" root>
                    Mon profil
                </SideBarListItemLink>

                <SideBarCollapseMenu title="Découvrir" path="/tags">
                    <SideBarListItemLink
                        to="https://pangaea-voyages.fr/"
                        externalLink
                    >
                        Pangaea voyages
                    </SideBarListItemLink>
                    <SideBarListItemLink
                        to="https://www.pangaea-sports.com/"
                        externalLink
                    >
                        Pangaea sports
                    </SideBarListItemLink>
                </SideBarCollapseMenu>
            </List>
        </Drawer>
    );
};

export default SideBar;
