import React from 'react';
import { Button, CircularProgress, Box } from '@mui/material';

// source: https://material-ui.com/components/progress/#interactive-integration

interface ButtonWithLoaderProps {
    children?: React.ReactNode;
    disabled?: boolean;
    disableLoader?: boolean;
    [buttonProps: string]: any;
}

const ButtonWithLoader = ({
    children,
    disabled,
    disableLoader,
    ...buttonProps
}: ButtonWithLoaderProps) => {
    return (
        <Box
            component="div"
            sx={{
                position: 'relative',
                display: 'inline-block',
            }}
        >
            <Button disabled={disabled} {...buttonProps}>
                {children}
            </Button>
            {disabled && !disableLoader && (
                <CircularProgress
                    size={24}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px', // la moitié du spinner
                        marginLeft: '-12px',
                    }}
                />
            )}
        </Box>
    );
};

export default ButtonWithLoader;
