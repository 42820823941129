import { useEffect } from 'react';
import { removeAuthTokens } from '@pangaea/shared/src/apollo/auth-tokens';
import { removeCurrentUser } from '@pangaea/shared/src/security/currentUser';
import { useNavigate } from 'react-router';
import CONFIGS from '../configs';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        removeAuthTokens(CONFIGS.localStorageKeys);
        removeCurrentUser(CONFIGS.localStorageKeys);
        navigate('/auth/connexion');
    }, [navigate]);

    return null;
};

export default Logout;
