import LocalStorageKeys from '../apollo/LocalStorageKeys';

export const getCurrentUser = (
    localStorageKeys: LocalStorageKeys
): any | null => {
    const user = localStorage.getItem(localStorageKeys.currentUser);
    if (user) return JSON.parse(user!);

    return null;
};

export const setCurrentUser = (
    localStorageKeys: LocalStorageKeys,
    currentUser: any | null
) => {
    if (currentUser) {
        localStorage.setItem(
            localStorageKeys.currentUser,
            JSON.stringify(currentUser)
        );
    } else {
        localStorage.removeItem(localStorageKeys.currentUser);
    }
};
export const removeCurrentUser = (localStorageKeys: LocalStorageKeys) => {
    setCurrentUser(localStorageKeys, null);
};
