import React from 'react';
import AlertProvider from '@pangaea/shared/src/tools/alert/AlertProvider';
import { LocalizationProvider } from '@mui/lab';
import frLocale from 'date-fns/locale/fr';
import { ApolloProvider } from '@apollo/client';
import BrowserRouter from '@pangaea/shared/src/router/BrowserRouter';
import PermissionsProvider from '@pangaea/shared/src/security/PermissionsProvider';
import PANGAEA_ROLES_HIERARCHY from '@pangaea/shared/src/security/pangaea-roles-hierarchy';
import DateFnsUtilsFR from '@pangaea/shared/src/date-fns/DateFnsUtilsFR';
import {
    getRoles,
    isAuthenticated as apolloIsAuthenticated,
} from '@pangaea/shared/src/apollo/auth-tokens';
import initApollo from './apollo/initApollo';
import Pages from './pages/Pages';
import CONFIGS from './configs';
import RefreshAuthenticatedEntityProvider from './features/entity/RefreshAuthenticatedEntityProvider';
import ThemeProvider from './mui/themeProvider';

const ApolloClient = initApollo();
const getUserRoles = () => getRoles(CONFIGS.localStorageKeys) || [];
const isAuthenticated = () => apolloIsAuthenticated(CONFIGS.localStorageKeys);
const App = () => {
    return (
        <ThemeProvider global>
            <LocalizationProvider
                dateAdapter={DateFnsUtilsFR}
                locale={frLocale}
            >
                <ApolloProvider client={ApolloClient}>
                    <PermissionsProvider
                        getUserRoles={getUserRoles}
                        rolesHierarchy={PANGAEA_ROLES_HIERARCHY}
                        isAuthenticated={isAuthenticated}
                        loginRoute="/auth/connexion"
                    >
                        <AlertProvider>
                            <BrowserRouter>
                                <RefreshAuthenticatedEntityProvider>
                                    <Pages />
                                </RefreshAuthenticatedEntityProvider>
                            </BrowserRouter>
                        </AlertProvider>
                    </PermissionsProvider>
                </ApolloProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default App;
