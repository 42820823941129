import React, { useMemo } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Typography, Box } from '@mui/material';
import { getCurrentUser } from '@pangaea/shared/src/security/currentUser';
import CONFIGS from '../../configs';

/**
 * Haut de la sidebar avec présentation de l'utilisateur connecté
 */
const SideBarHeader = () => {
    const entity = useMemo(() => getCurrentUser(CONFIGS.localStorageKeys), []);
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'secondary.main',
                p: 1,
                py: 2,
            }}
        >
            <AccountCircleIcon sx={{ fontSize: '4rem' }} />
            <Box ml={1}>
                <Typography variant="body1">
                    <b>
                        {entity.contactFirstName} {entity.contactLastName}
                    </b>
                </Typography>
                {/* <Typography variant="body2">
                    <b>Voyageur Pro</b>
                </Typography> */}
            </Box>
        </Box>
    );
};

export default SideBarHeader;
