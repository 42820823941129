import React, { useCallback } from 'react';
import { Link as MuiLink, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { setAuthTokens } from '@pangaea/shared/src/apollo/auth-tokens';
import { setCurrentUser } from '@pangaea/shared/src//security/currentUser';
import EntityResponse from '@pangaea/shared/src/features/entity/EntityResponse.model';
import ConnexionForm from '../../features/auth/ConnexionForm';
import CONFIGS from '../../configs';

const Connexion = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from: string | null = (location.state as any)?.from?.pathname || null;

    const handleSuccess = useCallback(
        (entity: EntityResponse) => {
            if (!entity.token || !entity.refreshToken || !entity.roles) {
                throw Error("Données manquantes dans l'utilisateur connecté.");
            }

            setCurrentUser(CONFIGS.localStorageKeys, entity);

            setAuthTokens(
                CONFIGS.localStorageKeys,
                entity.token,
                entity.refreshToken,
                entity.roles
            );
            if (from) {
                // si on a été redirigé sur la connexion après une déconnexion, on ne laisse pas d'entré dans l'historique
                navigate(from, { replace: true });
            } else {
                navigate('/');
            }
        },
        [navigate, from]
    );

    return (
        <>
            <Typography
                variant="h1"
                component="h1"
                mt={4}
                mb={3}
                sx={{ textDecoration: 'none' }}
            >
                Connexion
            </Typography>

            <ConnexionForm onSuccess={handleSuccess} />

            <Box mt={2}>
                <MuiLink component={Link} to="/auth/mot-de-passe-oublie">
                    Mot de passe oublié ?
                </MuiLink>
            </Box>
        </>
    );
};

export default Connexion;
