import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import AUTHENTICATED_ENTITY_FIELDS_FRAGMENT from '@pangaea/shared/src/features/entity/AuthenticatedEntity.fragment';
import GetEntityResponse from '@pangaea/shared/src/features/entity/GetEntityResponse';
import {
    getCurrentUser,
    setCurrentUser,
} from '@pangaea/shared/src/security/currentUser';
import { useAlert } from '@pangaea/shared/src/tools/alert';
import { setRoles } from '@pangaea/shared/src/apollo/auth-tokens';
import EntityResponse from '@pangaea/shared/src/features/entity/EntityResponse.model';
import CONFIGS from '../../configs';

export type RefreshAuthenticatedEntityCtxValue = (
    entity?: EntityResponse
) => void;

const initialValue: RefreshAuthenticatedEntityCtxValue = () => {};

const RefreshAuthenticatedEntityCtx =
    React.createContext<RefreshAuthenticatedEntityCtxValue>(initialValue);

export const useRefreshAuthenticatedEntity = () =>
    useContext(RefreshAuthenticatedEntityCtx);

export const GET_AUTHENTICATED_ENTITY = gql`
    ${AUTHENTICATED_ENTITY_FIELDS_FRAGMENT}
    query GetAuthenticatedEntity($id: ID!) {
        entity(id: $id) {
            ...AuthenticatedEntityFields
        }
    }
`;

interface RefreshAuthenticatedEntityProviderProps {
    children: React.ReactNode;
    onError?: (error: ApolloError) => void;
}

/**
 * Met à jour les infos stockées en local concernant l'entité connectée.
 * Refresh automatique au premier rendu du composant et possibilité de relancer des refreshs en appelant la fonction retournée par useRefreshAuthenticatedEntity.
 */
const RefreshAuthenticatedEntityProvider = ({
    children,
    onError,
}: RefreshAuthenticatedEntityProviderProps) => {
    const [refreshCount, setRefreshCount] = useState(1);
    const setAlert = useAlert();

    const [query, { data, error }] = useLazyQuery<GetEntityResponse>(
        GET_AUTHENTICATED_ENTITY,
        {
            fetchPolicy: 'network-only',
        }
    );

    const updateStorage = useCallback((entity: EntityResponse) => {
        setCurrentUser(CONFIGS.localStorageKeys, entity);
        setRoles(CONFIGS.localStorageKeys, entity.roles || null);
    }, []);

    useEffect(() => {
        const user = getCurrentUser(CONFIGS.localStorageKeys);
        if (user && user.id) {
            query({
                variables: {
                    id: user.id,
                },
            });
        }
    }, [query, refreshCount]);

    useEffect(() => {
        if (data && data.entity) {
            updateStorage(data.entity);
        }
    }, [data, updateStorage]);

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du rafraichissement des informations de votre compte.',
                'error'
            );
            onError && onError(error);
        }
    }, [error, setAlert, onError]);

    const refreshEntity = useCallback(
        (entity?: EntityResponse) => {
            if (entity && entity.roles) {
                // mise à jour instantannée si entity fournie
                updateStorage(entity);
            }
            setRefreshCount((val) => val + 1);
        },
        [setRefreshCount, updateStorage]
    );

    return (
        <RefreshAuthenticatedEntityCtx.Provider value={refreshEntity}>
            {children}
        </RefreshAuthenticatedEntityCtx.Provider>
    );
};

export default RefreshAuthenticatedEntityProvider;
