import React from 'react';
import { Box } from '@mui/material';
import SubmitButton from '@pangaea/shared/src/formik/SubmitButton';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import { useMutation, gql, FetchResult, ApolloError } from '@apollo/client';
import Yup from '@pangaea/shared/src/yup/yupFr';
import { useAlert } from '@pangaea/shared/src/tools/alert';
import { handleApolloError } from '@pangaea/shared/src/utils/error-helpers';
import { FormikPasswordField } from '@pangaea/shared/src/formik/PasswordField';
import EntityResponse from '@pangaea/shared/src/features/entity/EntityResponse.model';

export const CONNEXION_ENTITY = gql`
    mutation LoginEntity($email: String!, $password: String!) {
        loginEntity(input: { email: $email, password: $password }) {
            entity {
                id
                contactFirstName
                contactLastName
                roles
                token
                refreshToken
            }
        }
    }
`;

const entitySchema = Yup.object().shape({
    email: Yup.string().label('Le nom').required(),
    password: Yup.string().label('Le mot de passe').required(),
});

interface LoginEntity {
    email: string;
    password: string;
}

interface MutateEntityResult {
    loginEntity: {
        entity: Partial<EntityResponse>;
    };
}

interface EntityFormProps {
    onSuccess?: (user: Partial<EntityResponse>) => void;
    onError?: (err: ApolloError) => void;
}

const ConnexionForm = ({ onSuccess, onError }: EntityFormProps) => {
    const initialValues: LoginEntity = { email: '', password: '' };
    const setAlert = useAlert();
    const [mutate] = useMutation(CONNEXION_ENTITY);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={entitySchema}
            onSubmit={(
                values: LoginEntity,
                { setSubmitting, setErrors }: FormikHelpers<LoginEntity>
            ) => {
                mutate({ variables: values })
                    .finally(() => {
                        setSubmitting(false);
                    })
                    .then((res: FetchResult<MutateEntityResult>) => {
                        if (!res.data) {
                            throw Error('Missing data result');
                        }
                        onSuccess && onSuccess(res.data.loginEntity.entity);
                    })
                    .catch(
                        handleApolloError(
                            setErrors,
                            setAlert,
                            entitySchema,
                            onError
                        )
                    );
            }}
        >
            <Form>
                <Box mb={3}>
                    <Field
                        component={TextField}
                        type="email"
                        id="email"
                        label="Email"
                        name="email"
                        inputProps={{ 'data-testid': 'Email' }}
                    />
                    <Field
                        component={FormikPasswordField}
                        name="password"
                        id="password"
                        label="Mot de passe"
                        inputProps={{ 'data-testid': 'MotDePasse' }}
                    />
                </Box>
                <SubmitButton
                    buttonProps={{ 'data-testid': 'connexionFormSubmit' }}
                />
            </Form>
        </Formik>
    );
};

export default ConnexionForm;
