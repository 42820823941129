/* eslint-disable import/prefer-default-export */

/**
 * Extract id from an iri
 * getIdFromIri('/foos/5') = '5'
 */
export const getIdFromIri = (iri?: string | null): string | null => {
    if (!iri) return null;

    const split = iri.split('/');
    const id = split[split.length - 1];

    return id;
};
