import { Box } from '@mui/material';
import React from 'react';

interface ImageProps {
    src: string;
    alt?: string;
    title?: string;
    [pictureProps: string]: any;
}

const PictureAutoAjusted = ({
    src,
    alt,
    title,
    ...pictureProps
}: ImageProps) => {
    return (
        <Box
            component="img"
            sx={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
            }}
            {...pictureProps}
            alt={alt}
            src={src}
            title={title}
            loading="lazy"
        />
    );
};

export default PictureAutoAjusted;
