import React from 'react';
import { Typography } from '@mui/material';

const Home = () => {
    return (
        <>
            <Typography variant="h1" component="h1" sx={{ mt: 8 }}>
                Accueil
            </Typography>
        </>
    );
};

export default Home;
