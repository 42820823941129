import React from 'react';
import {
    CssBaseline,
    ScopedCssBaseline,
    ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import DestinationTypeCodes from '@pangaea/shared/src/features/destinationType/DestinationTypeCodes';
import { theme, psvTheme, pvTheme } from './theme';

export interface ThemeProviderProps {
    children: React.ReactNode;
    type?: DestinationTypeCodes | undefined;
    /** * Faut il appliquer le style globalement ? (donc au body parent) * false par défaut */
    global?: boolean;
}
const defineTheme = (typeFromProps: ThemeProviderProps['type']) => {
    switch (typeFromProps) {
        case DestinationTypeCodes.PSV:
            return psvTheme;
        case DestinationTypeCodes.PV:
            return pvTheme;
        default:
            return theme;
    }
};
function ThemeProvider({ children, type, global }: ThemeProviderProps) {
    const CssBaseLineComponent = global ? CssBaseline : ScopedCssBaseline;
    return (
        <MuiThemeProvider theme={defineTheme(type)}>
            <CssBaseLineComponent>{children}</CssBaseLineComponent>{' '}
        </MuiThemeProvider>
    );
}
export default ThemeProvider;
