import React from 'react';
import ButtonWithLoader from './ButtonWithLoader';

export interface SubmitButtonProps {
    children?: React.ReactNode;
    disabled?: boolean;
    isSubmitting?: boolean;
    buttonProps?: { [buttonProps: string]: any };
}

const SubmitButton = ({
    children,
    isSubmitting,
    buttonProps,
    disabled,
}: SubmitButtonProps) => {
    return (
        <ButtonWithLoader
            type="submit"
            disabled={isSubmitting || disabled}
            disableLoader={disabled && !isSubmitting}
            variant="contained"
            color="primary"
            {...buttonProps}
        >
            {children || 'Valider'}
        </ButtonWithLoader>
    );
};

export default SubmitButton;
