import { Theme, createTheme, darken, lighten } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';
import { frFR } from '@mui/material/locale';

// doc : https://mui.com/customization/theming/
// valeurs par défaut : https://mui.com/customization/default-theme/
// couleurs : https://mui.com/customization/color/

declare module '@mui/material/styles/createPalette' {
    interface TypeBackground {
        darker: string;
        dark: string;
        main: string;
        light: string;
        lighter: string;
    }

    interface SimplePaletteColorOptions {
        main: string;
        light?: string;
        lighter?: string;
        dark?: string;
        darker?: string;
        contrastText?: string;
    }

    interface PaletteColor {
        main: string;
        light: string;
        lighter: string;
        dark: string;
        darker: string;
        contrastText: string;
    }
}
const sportPangeae = '#7AC522';
const travelPangaea = '#BA2174';
const pangaeaPrimary = '#E57C18';
const pangaeaSecondary = '#EB9645';

// Fonction utilisé pour override le style de certains composants en fonction du theme appliqué
const themeModifier = (t: Theme) => ({
    typography: {
        h1: {
            fontSize: '3rem',
            fontWeight: 'bold',
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 'bold',
            marginTop: t.spacing(8),
            marginBottom: t.spacing(2),
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 'bold',
        },
        h4: {
            fontSize: '1.5rem',
        },
        h5: {
            fontSize: '1.25rem',
        },
        h6: {
            fontSize: '1.125rem',
        },
        body1: {
            fontSize: '1rem',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginTop: t.spacing(2),
                    minWidth: '225px',
                    '& .MuiFilledInput-root': {
                        borderRadius: t.shape.borderRadius,
                        backgroundColor: t.palette.common.white,
                        border: '1px solid rgba(0, 0, 0, 0)',
                        transition:
                            'border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        '&:hover': {
                            border: '1px solid rgba(0, 0, 0, 0.87)',
                            backgroundColor: t.palette.common.white,
                        },
                        '&.Mui-focused': {
                            border: '2px solid rgba(0, 0, 0, 0.87)',
                            backgroundColor: t.palette.common.white,
                        },
                        '&.Mui-error': {
                            borderColor: t.palette.error.main,
                        },
                    },
                    '& .MuiFilledInput-input': {
                        borderRadius: t.shape.borderRadius,
                    },
                },
            },
            defaultProps: {
                size: 'small',
                fullWidth: true,
                variant: 'filled',
                InputProps: { disableUnderline: true },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: t.palette.background.light,
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: t.palette.background.light,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: t.palette.secondary.main,
                },
            },
        },
        DialogTitle: { root: { backgroundColor: t.palette.secondary.main } },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&::before': {
                        // fond blanc dans le rond du radio
                        content: '""',
                        backgroundColor: t.palette.common.white,
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        pointerEvents: 'none',
                    },
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    flexShrink: 0,
                    width: 240,
                    '& .MuiDrawer-paper': {
                        width: 240,
                        backgroundColor: t.palette.secondary.light,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                },
            },
        },
        MuiDatePicker: {
            defaultProps: {
                InputProps: { disableUnderline: true },
            },
        },
    },
});

// Theme basique
const baseTheme: Theme = createTheme(
    {
        shape: {
            borderRadius: 14,
        },

        palette: {
            primary: {
                main: pangaeaPrimary,
                light: lighten(pangaeaPrimary, 0.3),
                lighter: lighten(pangaeaPrimary, 0.5),
                dark: darken(pangaeaPrimary, 0.3),
                darker: darken(pangaeaPrimary, 0.5),
                contrastText: '#fff',
            },
            secondary: {
                main: pangaeaSecondary,
                light: lighten(pangaeaSecondary, 0.3),
                lighter: lighten(pangaeaSecondary, 0.5),
                dark: darken(pangaeaSecondary, 0.3),
                darker: darken(pangaeaSecondary, 0.5),
                contrastText: '#fff',
            },
            background: {
                default: '#ffffff',
                paper: '#a9a9aa',
                darker: '#000000',
                dark: darken(pangaeaSecondary, 0.95),
                main: lighten(pangaeaPrimary, 0.95),
                light: lighten(pangaeaSecondary, 0.95),
                lighter: '#ffffff',
            },
        },
    },
    frFR
);
export const theme = createTheme(baseTheme, themeModifier(baseTheme));

// Theme Sport
const basePsvTheme = createTheme(theme, {
    palette: {
        secondary: {
            main: sportPangeae,
            light: lighten(sportPangeae, 0.3),
            lighter: lighten(sportPangeae, 0.9),
            dark: darken(sportPangeae, 0.3),
            darker: darken(sportPangeae, 0.9),
            contrastText: '#fff',
        },
    },
});
export const psvTheme = createTheme(basePsvTheme, themeModifier(basePsvTheme));

// Theme Loisirs
const basePvTheme = createTheme(theme, {
    palette: {
        secondary: {
            main: travelPangaea,
            light: lighten(travelPangaea, 0.3),
            lighter: lighten(travelPangaea, 0.9),
            dark: darken(travelPangaea, 0.3),
            darker: darken(travelPangaea, 0.9),
            contrastText: '#fff',
        },
    },
});
export const pvTheme = createTheme(basePvTheme, themeModifier(basePvTheme));
