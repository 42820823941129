import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import ForgotPasswordForm from '../../features/auth/ForgotPasswordForm';

const ForgottenPassword = () => {
    const navigate = useNavigate();

    const handleSuccess = useCallback(
        (token: string) => {
            navigate('/auth/nouveau-mot-de-passe/', {
                state: {
                    token,
                },
            });
        },
        [navigate]
    );

    return (
        <>
            <Typography variant="h2" component="h1" mt={4} mb={3}>
                Mot de passe oublié
            </Typography>

            <ForgotPasswordForm onSuccess={handleSuccess} />
        </>
    );
};

export default ForgottenPassword;
