import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import LayoutConnection from '../../layout/LayoutConnection';
import Connexion from './Connexion';
import ForgottenPassword from './ForgottenPassword';
import UpdatePassword from './UpdatePassword';
import CreatePassword from './CreatePassword';

const AuthPages = () => {
    return (
        <Routes>
            <Route path="/" element={<LayoutConnection />}>
                <Route index element={<Navigate to="connexion" replace />} />
                <Route path="connexion" element={<Connexion />} />
                <Route
                    path="mot-de-passe-oublie"
                    element={<ForgottenPassword />}
                />
                <Route
                    path="nouveau-mot-de-passe"
                    element={<UpdatePassword />}
                />
                <Route
                    path="creation-mot-de-passe"
                    element={<CreatePassword />}
                />
            </Route>
        </Routes>
    );
};

export default AuthPages;
