import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    Collapse,
    List,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material';
import { To, useMatch, useResolvedPath } from 'react-router';
import { getTypoSx } from './SideBarListItemLink';

interface SideBarCollapseMenuProps {
    title: string;
    children?: React.ReactNode;
    path: To;
}

const SideBarCollapseMenu = ({
    title,
    children,
    path,
}: SideBarCollapseMenuProps) => {
    const resolved = useResolvedPath(path);
    const match = useMatch({ path: resolved.pathname, end: false });
    const [openCollapse, setOpenCollapse] = useState(!!match);

    const handleClickTravels = () => {
        setOpenCollapse(!openCollapse);
    };

    return (
        <>
            <ListItemButton onClick={handleClickTravels}>
                <ListItemText
                    primary={
                        <Typography
                            variant="h6"
                            component="span"
                            sx={getTypoSx(true, !!match)}
                        >
                            {title}
                        </Typography>
                    }
                />
                {openCollapse ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse
                in={openCollapse}
                mountOnEnter={false}
                unmountOnExit={false}
            >
                <List>{children}</List>
            </Collapse>
        </>
    );
};

export default SideBarCollapseMenu;
