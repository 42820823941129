import { gql } from '@apollo/client';

const AUTHENTICATED_ENTITY_FIELDS_FRAGMENT = gql`
    fragment AuthenticatedEntityFields on Entity {
        id
        contactFirstName
        contactLastName
        roles
    }
`;

export default AUTHENTICATED_ENTITY_FIELDS_FRAGMENT;
