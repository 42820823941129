import React from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link as RouterLink } from 'react-router-dom';

const Header = () => {
    return (
        <Box
            pt={4}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Box>
                <Typography variant="h2" component="p" mt={0}>
                    Bienvenue sur votre espace Pangaea
                </Typography>
            </Box>
            <Box>
                <Button
                    component={RouterLink}
                    to="/deconnexion"
                    variant="contained"
                >
                    Déconnexion
                </Button>
            </Box>
        </Box>
    );
};

export default Header;
