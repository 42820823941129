import React from 'react';
import { Box } from '@mui/material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import { useMutation, gql, FetchResult, ApolloError } from '@apollo/client';
import Yup from '@pangaea/shared/src/yup/yupFr';
import { useAlert } from '@pangaea/shared/src/tools/alert';
import { handleApolloError } from '@pangaea/shared/src/utils/error-helpers';
import SubmitButton from '@pangaea/shared/src/formik/SubmitButton';
import Entity from '@pangaea/shared/src/features/entity/Entity.model';

export const FORGOTPASSWORD_ENTITY = gql`
    mutation CreatePasswordToken($email: String!) {
        createPasswordToken(input: { username: $email }) {
            passwordToken {
                token
            }
        }
    }
`;

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().label("L'email").required(),
});

interface ForgotPasswordEntity {
    email: string;
}

export type ForgetPasswordResult = {
    createPasswordToken?: { passwordToken: Partial<Entity> };
};

interface ForgetPasswordFormProps {
    onSuccess?: (result: string) => void;
    onError?: (err: ApolloError) => void;
}

const ForgotPasswordForm = ({
    onSuccess,
    onError,
}: ForgetPasswordFormProps) => {
    const initialValues: ForgotPasswordEntity = { email: '' };
    const setAlert = useAlert();
    const [mutate] = useMutation(FORGOTPASSWORD_ENTITY);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={forgotPasswordSchema}
            onSubmit={(
                values: ForgotPasswordEntity,
                {
                    setSubmitting,
                    setErrors,
                }: FormikHelpers<ForgotPasswordEntity>
            ) => {
                mutate({ variables: values })
                    .finally(() => {
                        setSubmitting(false);
                    })
                    .then((res: FetchResult<ForgetPasswordResult>) => {
                        const resForgotPassword: Partial<Entity> | undefined =
                            res.data?.createPasswordToken?.passwordToken;

                        if (!resForgotPassword || !resForgotPassword.token) {
                            throw Error('Missing data result');
                        }
                        onSuccess && onSuccess(resForgotPassword.token);
                    })
                    .catch(
                        handleApolloError(
                            setErrors,
                            setAlert,
                            forgotPasswordSchema,
                            onError
                        )
                    );
            }}
        >
            <Form>
                <Box mb={3}>
                    <Field
                        component={TextField}
                        inputProps={{ 'data-testid': 'email' }}
                        type="email"
                        id="email"
                        label="Email"
                        name="email"
                    />
                </Box>
                <SubmitButton
                    buttonProps={{ 'data-testid': 'forgotPasswordFormSubmit' }}
                />
            </Form>
        </Formik>
    );
};

export default ForgotPasswordForm;
