import React from 'react';
import { ListItemButton, ListItemText, Typography } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Box, SxProps } from '@mui/system';
import { useMatch, useResolvedPath } from 'react-router';

interface RootLink {
    /**
     * True si le lien se trouve à la racine de la sidebar. Sinon c'est un sous menu.
     */
    root?: boolean;
    disabled?: boolean;
    externalLink?: boolean;
}

type SideBarListItemLinkProps = RootLink & Pick<LinkProps, 'to' | 'children'>;

const iconFontSx: SxProps = { fontSize: 16 };
const getBullet = (match: boolean) => {
    if (match) {
        return <RadioButtonCheckedIcon sx={iconFontSx} />;
    }

    return <RadioButtonUncheckedIcon sx={iconFontSx} />;
};
const getColor = (root: boolean | undefined, match: boolean) => {
    if (!root) {
        return 'text.primary';
    }

    if (match) {
        return 'text.primary';
    }

    return 'text.secondary';
};

export const getTypoSx = (root: boolean | undefined, match: boolean) => ({
    fontWeight: root || match ? 'bold' : 'normal',
    color: getColor(root, match),
});

const SideBarListItemLink = ({
    root,
    children,
    to,
    disabled,
    externalLink,
}: SideBarListItemLinkProps) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });

    const buttonProps = externalLink
        ? { component: 'a', href: to.toString(), target: '_blank' }
        : { component: Link, to };

    return (
        <ListItemButton disabled={disabled} {...buttonProps}>
            <ListItemText
                disableTypography
                primary={
                    <Box display="flex" alignItems="center" pl={root ? 0 : 2}>
                        <Box mr={root ? 0 : 1} component="span">
                            {!root && getBullet(!!match)}
                        </Box>
                        <Typography
                            variant={root ? 'h6' : 'body1'}
                            component="span"
                            sx={getTypoSx(root, !!match)}
                        >
                            {children}
                        </Typography>
                    </Box>
                }
            />
        </ListItemButton>
    );
};

export default SideBarListItemLink;
