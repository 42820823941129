const CONFIGS = {
    apiEntrypoint: process.env.REACT_APP_API_ENTRYPOINT || '',
    localStorageKeys: {
        authToken:
            process.env.REACT_APP_AUTH_TOKEN_LOCALSTORAGE_KEY ||
            'authToken8nPg7hK52Cm2wYRH',
        refreshToken:
            process.env.REACT_APP_REFRESH_TOKEN_LOCALSTORAGE_KEY ||
            'refreshToken5DKToFjbFVrg4ztv',
        roles: 'roles',
        currentUser: process.env.REACT_APP_USER_LOCALSTORAGE_KEY || 'user',
    },
};

export default CONFIGS;
